import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";

export default function Clients() {


    return (
        <>
            <section className="mb-3">

                <div className="bg-cream rounded-5xl h-full p-6 py-12 md:p-24 mb-3">
                    <h3 className="text-4xl md:text-5xl font-bold text-[black]">Projects</h3>
                    <p className="text-[black] font-medium mt-4 ">We have happy clients that  appreciate our work</p>

                </div>


                <div className="grid lg:grid-cols-3 gap-3">
                    {/* <Link className="bg-cream group rounded-5xl h-full p-6 py-12 md:p-24 block relative overflow-hidden" to='/projects/neon-paint-house'>
                        <img className="w-12 absolute bottom-4 right-4 opacity-75" src="/images/neon-thumbnail.png" alt="" />
                        <div className="h-full">
                            <h2 className="text-xl font-semibold flex gap-x-2">Neon Paint House <FaArrowRight className="group-hover:scale-[1.3] transition block my-auto" size={20} /></h2>
                        </div>
                    </Link> */}
                    <a className="bg-cream text-[black] rounded-5xl h-full p-6 py-12 md:p-24 block relative overflow-hidden group" href='/projects/ss-lab-india'>
                        {/* <img className="w-12 absolute bottom-4 right-4 opacity-75" src="/images/ss-thumbnail.png" alt="" /> */}
                        <div className="h-full transition-all group-hover:scale-110">
                            <h2 className="text-xl text-[black] font-semibold flex gap-x-2">S.S Lab India<FaArrowRight className="group-hover:scale-[1.2] transition block my-auto" size={20} /></h2>
                        </div>


                    </a>
                    <a className="bg-cream text-[black] rounded-5xl h-full p-6 py-12 md:p-24 block relative overflow-hidden group" href='/projects/platform-me'>
                        {/* <img className="w-12 absolute bottom-4 right-4 opacity-75" src="/images/platform-thumbnail.png" alt="" /> */}
                        <div className="h-full transition-all group-hover:scale-110">
                            <h2 className="text-xl text-[black] font-semibold flex gap-x-2">Platform.Me<FaArrowRight className="group-hover:scale-[1.2] transition block my-auto" size={20} /></h2>
                        </div>
                    </a>
                    <a className="bg-cream text-[black] rounded-5xl h-full p-6 py-12 md:p-24 block relative overflow-hidden group" href='/projects/vinder'>
                        {/* <img className="w-12 absolute bottom-4 right-4 opacity-75" src="/images/vinder-thumbnail.png" alt="" /> */}
                        <div className="h-full transition-all group-hover:scale-110">
                            <h2 className=" text-xl text-[black]  font-semibold flex gap-x-2">Vinder<FaArrowRight className="group-hover:scale-[1.2] transition block my-auto" size={20} /></h2>
                        </div>
                    </a>
                </div>
            </section>
        </>
    )
}

// import { Link } from "react-router-dom";
// import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
// import { useState } from "react";

// export default function Clients() {

//     const [sectionOpen, setSectionOpen] = useState(false)

//     function handleSectionOpen(name) {

//         if (sectionOpen == name) {
//             setSectionOpen('')
//         } else {
//             setSectionOpen(name)
//         }
//     }

//     return (
//         <>
//             <section className="mb-3">

//                 <div className="bg-cream rounded-5xl h-full p-6 py-12 md:p-24 mb-3">
//                     <h3 className="text-4xl md:text-5xl font-bold text-[black]">Projects</h3>
//                     <p className="text-[black] font-medium mt-4 ">We have happy clients that  appreciate our work</p>

//                 </div>


//                 <div className="grid lg:grid-cols-2 gap-3">
//                     {/* <Link className="bg-cream group rounded-5xl h-full p-6 py-12 md:p-24 block relative overflow-hidden" to='/projects/neon-paint-house'>
//                         <img className="w-12 absolute bottom-4 right-4 opacity-75" src="/images/neon-thumbnail.png" alt="" />
//                         <div className="h-full">
//                             <h2 className="text-xl font-semibold flex gap-x-2">Neon Paint House <FaArrowRight className="group-hover:scale-[1.3] transition block my-auto" size={20} /></h2>
//                         </div>
//                     </Link> */}
//                     <div onClick={() => { handleSectionOpen('platform-me') }} className={`bg-cream text-[black] transition-all ${sectionOpen == 'platform-me' ? 'rounded-[3.2rem]' : 'rounded-5xl'} h-full p-2 pt-12 md:p-24 block relative overflow-hidden group" to='/projects/ss-lab-india`}>
//                         {/* <img className="w-12 absolute bottom-4 right-4 opacity-75" src="/images/ss-thumbnail.png" alt="" /> */}
//                         <div className="h-fit transition-all ">
//                             <h2 className="text-xl text-[black] font-semibold flex gap-x-2 pl-6">S.S Lab India<FaArrowRight className={`group-hover:scale-[1.2] transition block my-auto" size={20}  ${sectionOpen == 'platform-me' && 'rotate-90'}`} /></h2>
//                         </div>

//                         <div className={`${sectionOpen == 'platform-me' ? 'h-auto' : 'h-0'} transition-all`}>
//                             <div className="mt-6 lg:col-span-1 md:col-span-2 relative w-fit h-fit">
//                                 <p className="md:hidden flex gap-x-3 absolute top-1/2 -translate-y-1/2 -left-28 rotate-90">Swipe For Preview<FaArrowLeft size={20} className="rotate-180 my-auto" /></p>
//                                 <p className="md:hidden flex gap-x-3 absolute top-1/2 -translate-y-1/2 -right-28 rotate-90">Swipe For Preview<FaArrowLeft size={20} className="rotate-180 my-auto" /></p>

//                                 <img
//                                     src="/images/iphone.png"
//                                     className=" block relative z-20 invert-0 brightness-100 saturate-100 filter"
//                                     alt="iPhone Mockup"
//                                 />
//                                 <iframe
//                                     src="https://platform-me.vercel.app/"
//                                     className="absolute rounded-[16%/7%] z-30 top-[0.3rem] left-[0.5rem] w-[95.8%] h-[98.6%] border-none "
//                                 />
//                             </div>
//                         </div>
//                     </div>
//                     <div className="h-fit bg-cream text-[black] rounded-5xl  p-6 py-12 md:p-24 block relative overflow-hidden group" to='/projects/platform-me'>
//                         {/* <img className="w-12 absolute bottom-4 right-4 opacity-75" src="/images/platform-thumbnail.png" alt="" /> */}
//                         <div className="h-full transition-all ">
//                             <h2 className="text-xl text-[black] font-semibold flex gap-x-2">Platform.Me<FaArrowRight className="group-hover:scale-[1.2] transition block my-auto" size={20} /></h2>
//                         </div>
//                     </div>
//                     <Link className="h-fit bg-cream text-[black] rounded-5xl  p-6 py-12 md:p-24 block relative overflow-hidden group" to='/projects/vinder'>
//                         {/* <img className="w-12 absolute bottom-4 right-4 opacity-75" src="/images/vinder-thumbnail.png" alt="" /> */}
//                         <div className="h-full transition-all ">
//                             <h2 className=" text-xl text-[black]  font-semibold flex gap-x-2">Vinder<FaArrowRight className="group-hover:scale-[1.2] transition block my-auto" size={20} /></h2>
//                         </div>
//                     </Link>
//                 </div>
//             </section>
//         </>
//     )
// }