import Chip from "./Chip";
import { useFormik } from 'formik';
import { useRef, useState } from "react";
import * as Yup from 'yup';
import { FaGoogle, FaShopify } from "react-icons/fa";
import { SiMaterialdesign } from "react-icons/si";
import { FaLaptopCode } from "react-icons/fa6";
import { MdAnimation } from "react-icons/md";
import { AiOutlineProduct } from "react-icons/ai";
import { CgIfDesign } from "react-icons/cg";
import { PiThreeDFill } from "react-icons/pi";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import SplitType from "split-type";
import { Link } from "react-router-dom";


export default function AboutMe() {

    useGSAP(() => {
        const text = SplitType.create('.handle-website');

        gsap.fromTo(
            text.chars,
            {
                y: 50,
                opacity: 0
            },
            {
                scrollTrigger: {
                    trigger: '#handle-website-container'
                },
                y: 0,
                opacity: 1,
                stagger: 0.03,
                duration: 1,
                ease: 'power4.out',

            }
        );
    })


    const [selectedService, setService] = useState('')
    const [serviceFormOpen, setFormOpen] = useState(false)

    const servicesForm = useRef(null)

    function promptAndSendEnquiry(e) {

        // if (!serviceFormOpen) {
        //     servicesForm.current.style.display = 'block'
        // }
        // if (!serviceFormOpen) {
        //     servicesForm.current.style.display = 'block'
        // }

        // const div = (e.currentTarget)
        // const service = div.querySelector('p').innerText;
        // const div = (e.currentTarget)
        // const service = div.querySelector('p').innerText;

        // setService(service)
        // setService(service)
    }


    const SignupSchema = Yup.object().shape({
        contact: Yup.string()
            .min(3, 'Name Too Short!')
            .max(50, 'Name Too Long!')
            .required('Please Enter Your Name'),
        name: Yup.string()
            .min(8, 'Contact Details Too Short!')
            .max(50, 'Contact Details Too Long!')
            .required('Please Enter Your Contact')
    });
    const formik = useFormik({
        initialValues: {
            contact: '',
            name: ''

        },
        validationSchema: SignupSchema,

        onSubmit: values => {
            alert(JSON.stringify(values, null, 2));
        },
    });

    return (
        <>
            <div className="-translate-y-6" id="our-services"></div>
            <section className="">

                <div className="bg-white rounded-5xl h-full  p-6 py-12 md:p-24 mb-3">
                    <h3 className="text-4xl md:text-5xl font-bold text-[black]">What We Offer ?</h3>
                    <p className="text-[black] font-semibold my-4 mb-8">We offer a wide variety of services for your website</p>
                    <p className="text-sm font-medium text-[#202020]"></p>
                    <div className="mt-6 flex gap-x-2 gap-y-2 flex-wrap">

                        <div onClick={promptAndSendEnquiry}>
                            <Chip icon={<AiOutlineProduct />} text={'MVP Development'} />
                        </div>

                        <div onClick={promptAndSendEnquiry}>
                            <Chip icon={<CgIfDesign />} text={'Website Redesign '} />
                        </div>

                        <div onClick={promptAndSendEnquiry}>
                            <Chip text={'UI Design'} icon={<SiMaterialdesign />} />
                        </div>

                        <div onClick={promptAndSendEnquiry}>
                            <Chip icon={<FaLaptopCode />} text={'Web Development'} />
                        </div>

                        <div onClick={promptAndSendEnquiry}>
                            <Chip icon={<MdAnimation />} text={'Animated Websites'} />
                        </div>

                        <div onClick={promptAndSendEnquiry}>
                            <Chip icon={<FaGoogle />} text={'SEO'} />
                        </div>

                        <div onClick={promptAndSendEnquiry}>
                            <Chip icon={<FaShopify />} text={'E-Commerce Store (Custom UI)'} />
                        </div>

                    </div>

                    {/* <div className="group">
                        <Link className=" transition group-hover:bg-black  block bg-[white] w-fit px-5 py-1 mt-8 border-[2px] border-solid border-black rounded-2xl" to='services'>
                            <button className="bg-transparent transition group-hover:text-white text-[black]  font-medium">View All Services</button>
                        </Link>
                    </div> */}

                    <div ref={servicesForm} className="bg-light-black py-4 px-4 rounded-xl mt-6 hidden overflow-hidden">

                        <form onSubmit={formik.handleSubmit}>
                            <label className="text-white " htmlFor="name">I am</label>
                            <input
                                id="name"
                                name="name"
                                type="name"
                                autoComplete="name"
                                onChange={formik.handleChange}
                                value={formik.values.name} placeholder="Your Name"
                                className="bg-transparent text-sm mx-1.5 border-b border-[black] text-white"
                            />

                            <label className="text-white" htmlFor="contact"> & my contact is</label>
                            <input
                                id="contact"
                                name="contact"
                                type="contact"
                                autoComplete="phone"
                                onChange={formik.handleChange}
                                value={formik.values.contact} placeholder="Phone/E-Mail"
                                className="bg-transparent text-sm mx-1.5 border-b border-[black] text-white"
                            />
                            <div className="flex gap-y-2 flex-col sm:flex-row gap-x-1.5 mt-3">
                                <p className="text-white text-sm my-auto">I want to enquire about</p>
                                <Chip text={selectedService} variant={'transparent'} />

                            </div>
                            <button type="submit" className="text-xs bg-red text-[white] font-semibold p-4 py-2 rounded-3xl mt-4 hover:bg-[#aaaaaa] hover:text-[black] hover:font-medium transition">Submit</button>
                            <br />
                            {formik.touched.name && formik.errors.name ? (
                                <p className="mt-1.5 text-xs text-red">*{formik.errors.name}</p>
                            ) : null}
                            {formik.touched.contact && formik.errors.contact ? (
                                <p className="text-xs text-red">*{formik.errors.contact}</p>
                            ) : null}

                        </form>

                    </div>
                </div>
            </section>
        </>
    )
}