import './App.css';
import Home from './Pages/Home/Home';
import UIHome from './Pages/UI/Home';
import Footer from './GlobalComponents/Footer';
import Header from './GlobalComponents/Header';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import TextGoUp from './Pages/UI/Elements/Hero/TextGoUp';
import HandToCTA from './Pages/UI/Elements/CTA/HandToCTA';
import StoryBook from './Pages/UI/Elements/Story/StoryBook';
import OneToFull from './Pages/UI/Elements/Slider/OneToFull';
import About from './Pages/About/About'
import { AnimatePresence } from 'framer-motion'
import WorldOfNeon from './Pages/Projects/NeonPaintHouse';
import PlatformMe from './Pages/Projects/PlatformMe';
import Vinder from './Pages/Projects/Vinder';
import SSLabIndia from './Pages/Projects/SSLabIndia';
function App() {

  const location = useLocation()

  return (
    <>
      {/* <Header /> */}
      <AnimatePresence mode='wait'>
        <Routes location={location} key={location.pathname}>
          {/* <Route path='/ui-elements' element={<UIHome />} />
          <Route path='/ui-elements/text-go-up' element={<TextGoUp />} />
          <Route path='/ui-elements/hand-to-cta' element={<HandToCTA />} />
          <Route path='/ui-elements/hand-to-cta' element={<HandToCTA />} />
          <Route path='/ui-elements/story-book' element={<StoryBook />} />
          <Route path='/ui-elements/one-to-full' element={<OneToFull />} /> */}
          {/* <Route path='/about-us' element={<About />} /> */}
          <Route path='/projects/neon-paint-house' element={<WorldOfNeon />} />
          <Route path='/projects/platform-me' element={<PlatformMe />} />
          <Route path='/projects/vinder' element={<Vinder />} />
          <Route path='/projects/ss-lab-india' element={<SSLabIndia />} />
          <Route path='/' element={<Home />} />



        </Routes>
      </AnimatePresence>
      <Footer />
    </>
  );
}

export default App;
