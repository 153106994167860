import UIUXCard from "./components/UIUXCard";
import { RiScrollToBottomLine } from "react-icons/ri";
import { FaArrowLeft } from "react-icons/fa6";

export default function Vinder() {
    return (
        <section className="first">

            <div className="flex flex-col justify-between">
                <div>
                    <h3 className="text-4xl md:text-5xl font-bold">Vinder</h3>
                    <p className="text-[white] font-base mt-2 text-sm md:text-base lg:text-md">An anonymous dating app launched for a fest at a college</p>
                </div>

            </div>

            <div className="h-full mb-3 mt-12">
                <div className="grid md:grid-cols-2 gap-3 mb-3">

                    <div className=" bg-red text-[white] text-center flex rounded-5xl h-full p-6 py-12 md:p-16 mb-3">
                        <div className="h-fit my-auto w-fit mx-auto space-y-4">
                            <h3 className="text-base  text-inherit">what did we do?</h3>
                            <p className="m-auto text-2xl md:text-3xl text-center text-[white] font-semibold">UI/UX<br/>FullStack Web App</p>
                        </div>
                    </div>
                    <div className="bg-black [&_*]:text-[white] rounded-5xl h-full p-6 py-12 md:p-16 mb-3 flex text-center ">
                        <div className="h-fit my-auto w-fit mx-auto">
                            <div className="space-y-4" >
                                <h3 className="text-base">project goals</h3>
                                <p className="text-2xl md:text-3xl  font-semibold">Making it pop out<br /> Fun elements<br /> Simple Animations</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid md:grid-cols-3  text-[white] text-center rounded-5xl h-full p-6 py-12 md:p-16 mb-3">
                    <div className="lg:block hidden"></div>
                    <div className="lg:col-span-1 md:col-span-2 relative w-fit h-fit">
                        <p className="md:hidden flex gap-x-3 absolute top-1/2 -translate-y-1/2 -left-28 rotate-90">Swipe For Preview<FaArrowLeft size={20} className="rotate-180 my-auto" /></p>
                        <p className="md:hidden flex gap-x-3 absolute top-1/2 -translate-y-1/2 -right-28 rotate-90">Swipe For Preview<FaArrowLeft size={20} className="rotate-180 my-auto" /></p>

                        <img
                            src="/images/iphone.png"
                            className=" block relative z-20 invert-0 brightness-100 saturate-100 filter"
                            alt="iPhone Mockup"
                        />
                        <iframe
                            src="https://vinder-12239.web.app"
                            className="absolute rounded-[16%/7%] z-30 top-[0.3rem] left-[0.5rem] w-[95.8%] h-[98.6%] border-none "
                        />
                    </div>
                    <div className="hidden md:block h-fit my-auto">
                        <div className="flex align-middle gap-2">
                            <FaArrowLeft size={30} className="my-auto" />

                            <p className="text-xl my-auto">Scroll Here</p>
                            {/* <RiScrollToBottomLine size={30} /> */}

                        </div>
                    </div>
                </div>
                {/* <div className="bg-[#202020]      text-[white] rounded-5xl h-full p-6 py-12 md:p-24 mb-3">
                    <h3 className="text-2xl md:text-3xl font-semibold">Website</h3>
                    <p className="text-[white] font-base mt-2 text-sm md:text-base lg:text-md">Some screenshots and elements from the website</p>

                    <div className="grid grid-cols-1 md:grid-cols-2 mt-12 gap-8">

                        <div>
                            <div className="h-fit my-auto">
                                <h4 className="text-xl md:text-lg bg-transparent border border-white rounded p-0.5 px-2 text-[white] w-fit font-medium">Bold & Colorful</h4>
                                <p className="text-[white] font-base mt-2 text-sm md:text-base lg:text-md">We took a unique way to the navbar </p>
                            </div>
                            <div className="mt-6">
                                <img className="rounded-xl " src="/projects/won/bold.png" />
                            </div>
                        </div>
                        <div className="md:col-span-2">
                            <div className="h-fit my-auto">
                                <h4 className="text-xl md:text-lg bg-transparent border border-white rounded p-0.5 px-2 text-[white] w-fit font-medium">Animations</h4>
                                <p className="text-[white] font-base mt-2 text-sm md:text-base lg:text-md">We took a unique way to the navbar </p>
                            </div>
                            <div className="mt-6 grid md:grid-cols-2 gap-6">
                                <video autoPlay playsInline muted loop className="rounded-xl " src="/projects/won/anim-hero-bg.mp4" />
                                <video autoPlay playsInline muted loop className="rounded-xl  w-52" src="/projects/won/mobile-anim.mp4" />

                            </div>

                        </div>
                    </div>
                </div> */}

            </div>

        </section>
    )
}